import { useEffect } from "react";

import { useSetLoggedIn, useSetProfileData } from "_common/hooks/auth";
import { useSetUserOnBoardingAborted }       from "_common/hooks/global";

const ResetStore = () => {
	const setUserOnBoardingAborted = useSetUserOnBoardingAborted ();
	const setProfile               = useSetProfileData ();
	const setLoggedIn              = useSetLoggedIn ();

	useEffect ( () => {
		setUserOnBoardingAborted ( false );
		setProfile ( undefined );
		setLoggedIn ( false );
	}, [] );

	return null;
};

export default ResetStore;
